function ResearchSuSy() {
  return (
    <section className="container section">
      <div className="has-text-centered mb-5">
        <img
          src="/images/work/susy_logo.png"
          alt="SuSy Logo"
          style={{ maxWidth: '200px' }}
        />
      </div>


      <h2 className="is-size-3 has-text-weight-bold mb-5 has-text-centered">
        SuSy: Synthetic Content Detection
      </h2>

      <div className="content has-text-centered">
        <div className="buttons is-centered mb-5">
          <a
            href="https://huggingface.co/HPAI-BSC/SuSy"
            className="button is-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            SuSy Model
          </a>
          <a
            href="https://huggingface.co/datasets/HPAI-BSC/SuSy-Dataset"
            className="button is-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Datasets
          </a>
          <a
            href="https://arxiv.org/abs/2409.14128"
            className="button is-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Research Paper
          </a>
          <a
            href="https://huggingface.co/spaces/HPAI-BSC/SuSy"
            className="button is-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Free Online Demo
          </a>
          <a
            href="https://huggingface.co/spaces/HPAI-BSC/SuSyGame"
            className="button is-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Free Online Game
          </a>
        </div>

        <p className="has-text-weight-semibold mb-5">
          Winner of the 18th RES Users Conference "Best Poster" award.
          <br />
          Winner of the "Best Master Thesis" 2024 award by ACIA (Associació
          Catalana d’Intel·ligència Artificial).
        </p>

        <hr />

        <p className="mb-6">
          As of today, AI-generated images and videos can be easily created by
          anyone. Synthetic content that can fool humans is already prevalent.
          Society demands methods to identify synthetic content to maintain
          digital trust. This includes industries like insurance companies
          preventing fraud, media broadcasters verifying content, and the
          justice system needing evidence verification methods. In response,
          the European Parliament approved the AI Act, which mandates adding
          disclaimers to synthetic content and providing detection methods (<a href="https://eur-lex.europa.eu/eli/reg/2024/1689/oj">AI Act, Article 50</a>).
        </p>

        <p className="mb-6">
          The field of synthetic content detection is currently a race between
          content generators and detectors. New, more realistic generative
          models appear frequently, making it challenging to keep up. To win
          this race, detectors must be universal and capable of detecting
          content from future, unseen models. This demands generalization over
          time.
        </p>

        <h2 className="is-size-4 has-text-weight-bold mb-5">
          Present and Future Generalization of Synthetic Image Detectors
        </h2>
        <p className="mb-6">
          Can synthetic content be reliably detected today? What factors does
          it depend on? We address these questions in two experiments: First, a
          controlled study training image detectors and assessing their
          performance under different conditions. The work is summarized in the
          poster below (winner of the RES Users Conference Best Poster Award,
          2024). Second, a broad benchmark of existing detectors and generators
          using a dataset gathered in the wild, compared with human performance.
        </p>

        <div className="is-flex is-justify-content-center mb-6">
          <embed src="/images/work/susy_poster.pdf" width="800px" height="1150px" />
        </div>

        <h3 className="is-size-4 has-text-weight-semibold mb-5">Train Experiments</h3>
        <p className="mb-6">
          The poster highlights significant findings. In the first experiment,
          older synthetic datasets are more difficult to generalize to. Models
          not trained on them perform suboptimally. This is notable because
          older datasets are the least realistic and often easier for humans to
          recognize as fake. Detectors trained on newer data generalize better,
          emphasizing the need to keep detectors tuned with the latest data.
          Experiment four provides an estimate of current detector quality,
          showing performance close to that of human evaluators. These findings
          support the use of these models as decision-support tools, e.g., to
          prioritize samples for review.
        </p>

        <h3 className="is-size-4 has-text-weight-semibold mb-5">
          Open Benchmarking
        </h3>
        <p className="mb-6">
          To further study generalization, we tested 11 synthetic image
          detectors on 17 synthetic datasets, produced by 14 different
          generative models. The results are displayed in the table below.
        </p>

        <div className="is-flex is-justify-content-center mb-6">
          <embed src="/images/work/susy_table.png" width="1100px" height="800px" />
        </div>

        <p className="mb-6">
          The table shows that current synthetic image detection models exhibit
          imbalanced error rates. False positives (real images labeled as
          synthetic) and false negatives (synthetic images labeled as real)
          diverge significantly. In this context, understanding the needs of each use case is
          crucial, as it clarifies priorities with regards to error types. The
          different behaviors of detectors also suggest the potential for
          ensemble-based designs where multiple detectors could complement each
          other.
        </p>

          Another relevant insight is the difference between detector performance on datasets generated by the same model (e.g., DALLE-3, SDXL). Even though these datasets are produced using the same underlying generator (and will therefore have a similar degree of realism) the changes induced by the user producing the data (e.g., prompt changes, image postprocessing) cause significant changes in detection rates. This finding points to the insability of detectors, and the need for diverse evaluations prior to deployment.
        
	The changes seen in detection when reducing the resolution of data, are shown in the "Average (Resize)" rows. Some models are completely unaffected by it (LGrad, Rine). On other models (e.g., GramNet, DeFake), the change of resolution affects mostly their threshold of detection, as illustrates the simetrical change in average recall between Synthetic and Real (i.e., +x in one, -x in the other). For these models, their performance as an average between both classes remain the same. We could consider these to be multiscale detectors. Finally, a third family of models are monoscale, seeing their performance drop drastically under resolution change (e.g., DIMD). 

        <hr />

        <h3 className="is-size-4 has-text-weight-semibold mb-5">Paradoxes</h3>
        <ul className="ml-5 mb-6">
	  Throughout this work, several paradoxes have emerged, which are of relevance for understanding the present and near future of synthetic image detection. These are:
          
	  <li><b>Old generators are challenging for models because they are easy for humans.</b></li>
	  While training to detect synthetic data produced by older generators (and thus lacking realism) yields highly accurace models, detecting this type of data without specific training (i.e., generalizing to it) is particularly hard (bottom row of Table 2 in the paper).
	Most likely, this is caused by the artifacts introduced by early generators, which makes these data unique and belonging to a different distribution. Simply put, all realistic generative models produce similar images (easier to generlize), while less realistic generators produce higher image diversity (harder to generalize). This paradox highlights the necessity of always using human supervision, as human generalize very well to low-realism samples.
          <li><b>Race equilibrium between synthetic generators and detectors.</b></li>
	  To train synthetic image detectors you need synthetic images. The differences between these and real images are learnt by detectors and used for identification. One of the findings of our work is that detectors trained on more realistic images are better at recognizing synthetic images of all kinds (i.e., they generalize better. Last column of Table 2 in the paper). Meanwhile, generators can incorporate adversarial components, in which a detector is used to guide the generator towards producing more indistinguishable synthetic outputs (i.e., learn to fool the detector).
	  This duality sets the generator-detector fields into a race which is constantly close, as the progress of one side will permeate to the other.

          <li><b>Dual-use concerns and reproducibility issues in research.</b></li>
	Open and <i>reproducible research</i> provides the highest degrees of scientific reliability and quality. <i>Dual use research</i>, which enables undesirable applications beyond those proposed by the authors, tests the ethical limits of reproducibility. Synthetic image detection is a good example of dual use. An openly released detector model can be used to train better generators, and generators tuned to fool it. For this reason, training and releasing the best possible detector does not seem appropriate, while explaining how to build better ones and how to use them effectively seems fine.


        </ul>

        <div className="content has-text-centered">
          <div className="buttons is-centered">
            <a
              href="https://huggingface.co/HPAI-BSC/SuSy"
              className="button is-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              SuSy Model
            </a>
            <a
              href="https://huggingface.co/datasets/HPAI-BSC/SuSy-Dataset"
              className="button is-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              SuSy Dataset
            </a>
            <a
              href="https://arxiv.org/abs/2409.14128"
              className="button is-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Research Paper
            </a>
            <a
	      href="https://huggingface.co/spaces/HPAI-BSC/SuSy" 
              className="button is-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Free Online Demo
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResearchSuSy;

